<template>
  <div class="indexbox">
    <div class="bread">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/setting' }">
          Setting
        </el-breadcrumb-item>
        <el-breadcrumb-item>System setting</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button :loading='btn_loading' @click="save()">
        <img src="../../assets/img/top01.png"/>
        Save
      </el-button>
    </div>
    <div class="searchbox">
      <div v-loading="loading" class="tablebox">
        <el-form ref="forms" :model="form" :rules="rules" label-width="380px">
          <el-form-item label="WIP Account" prop="WIPAccount">
            <el-select v-model="form.WIPAccount" filterable placeholder="Please select">
              <el-option v-for="item in arr" :key="item.Code" :label="item.DisplayName" :value="item.Code" filterable>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="SOH Account" prop="SOHAccount">
            <el-select v-model="form.SOHAccount" filterable placeholder="Please select">
              <el-option v-for="item in arr" :key="item.Code" :label="item.DisplayName" :value="item.Code" filterable>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="InTransit Account" prop="inTransitAccount">
            <el-select v-model="form.inTransitAccount" filterable placeholder="Please select">
              <el-option v-for="item in arr" :key="item.Code" :label="item.DisplayName" :value="item.Code" filterable>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Cost Allocation">
            <el-radio-group v-model="form.costAllocation">
              <el-radio label="1">According to product quantity</el-radio>
              <el-radio label="2">According to product area</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["api_id"]),
  },
  data() {
    return {
      loading: false,
      form: {
        WIPAccount: "",
        SOHAccount: "",
        inTransitAccount: "",
        costAllocation: "",
      },
      rules: {
        WIPAccount: [{trigger: "blur", message: "Please Select"}],
        SOHAccount: [{trigger: "blur", message: "Please Select"}],
        inTransitAccount: [{trigger: "blur", message: "Please Select"}],
      },
      btn_loading: false,
      arr: [],
    };
  },
  methods: {
    getlist() {
      this.loading = true;
      this.$post({
        url: "/app/dear/accounts",
        data: {
          api_id: this.api_id,
        },
        success: (res) => {
          this.arr = res.data;
          this.getdata();
        },
        tip: () => {
          this.loading = false;
        },
      });
    },
    // 获取 api 详情设置
    getdata() {
      this.$post({
        url: "/app/api_config/info",
        data: {
          api_id: this.api_id,
        },
        success: (res) => {
          this.form = {
            WIPAccount: res.data.WIPAccount,
            SOHAccount: res.data.SOHAccount,
            inTransitAccount: res.data.inTransitAccount,
            costAllocation: res.data.costAllocation.toString(),
          };
          this.loading = false;
        },
        tip: () => {
          this.loading = false;
          this.form = {
            WIPAccount: "",
            SOHAccount: "",
            inTransitAccount: "",
            costAllocation: "",
          };
        },
      });
    },
    save() {
      this.$refs.forms.validate(valid => {
        if (valid) {
          this.$post({
            url: "/app/api_config/setConfig",
            data: {
              api_id: this.api_id,
              ...this.form
            },
            success: res => {
              this.$message({
                message: "Success",
                type: "success"
              });
              setTimeout(() => {
                this.btn_loading = false;
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.btn_loading = false;
              }, 1500);
            }
          });
        }
      });
    },
  },
  created() {
    this.getlist();
  },
};
</script>
<style lang="scss" scoped>
.indexbox {
  width: 100%;
  height: 100%;

  .bread {
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    /deep/ .el-button {
      font-weight: 500;
      color: #3b3b3b;
      background-color: #d6f3e3;
      border-radius: 10px;
      border: none;
      padding: 7px 13px;

      span {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }

  .searchbox {
    width: 100%;
    padding: 30px;

    .tablebox {
      background-color: #ffffff;
      border-radius: 10px;
      width: 100%;
      padding: 30px 30px 10px 0;
    }
  }

  /deep/ .el-form-item {
    margin-bottom: 30px;

    .el-form-item__content {
      .el-select,
      .el-button,
      .el-date-editor {
        width: 100%;
      }
    }
  }
}
</style>
